import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import { ApiContext } from "../App.js";

import about from "../img/about.jpg";

import Loader from "../components/Loader";
import { displayLoading } from "../components/Loader";
import { hideLoading } from "../components/Loader";

function About() {
  const [desc, setDesc] = useState(null);
  const baseUrl = useContext(ApiContext);
  const location = useLocation();
  const slug = location.pathname.replace(/\//g, "");
  useEffect(() => {
    AOS.init();
    displayLoading();
    fetch(baseUrl + "/wp-json/wp/v2/pages?slug=" + slug)
      .then((response) => response.json())
      .then((data) => {
        setDesc(data[0].acf.about_desc);
        hideLoading();
      });
    // eslint-disable-next-line
  }, []);
  return (
    <div data-aos="fade-up" className="a-container">
      <div className="a-wrapper">
        <h1 className="a-subtitle orange">O mnie</h1>
        <div className="m-columns-50">
          <div className="m-columns-50__item">
            <div className="m-columns-50__item m-columns-50__item--img">
              <img src={about} alt="O mnie" data-aos="fade-left" />
            </div>
          </div>
          <div className="m-columns-50__item m-content green">
            <Loader />
            <div className="" dangerouslySetInnerHTML={{ __html: desc }} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
