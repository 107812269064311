import React, { useContext, useState, useEffect } from "react";
import { ApiContext } from "../App.js";
import { useLocation } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";

import Loader from "../components/Loader";
import { displayLoading } from "../components/Loader";
import { hideLoading } from "../components/Loader";
function Portfolio() {
  const [portfolio, setPortfolio] = useState([]);
  const baseUrl = useContext(ApiContext);
  const location = useLocation();
  const slug = location.pathname.replace(/\//g, "");
  useEffect(() => {
    AOS.init();
    displayLoading();
    fetch(
      baseUrl + "/wp-json/wp/v2/pages?slug=" + slug + "&acf_format=standard"
    )
      .then((response) => response.json())
      .then((data) => {
        setPortfolio(data[0].acf.portfolio_item);
        hideLoading();
      });
    // eslint-disable-next-line
  }, []);
  return (
    <div data-aos="fade-up" className="a-container">
      <div className="a-wrapper">
        <h1 className="a-subtitle orange">Portfolio</h1>
        <Loader />
        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
          }}
          effect={"coverflow"}
          grabCursor={true}
          // loop={true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination]}
          className="m-swiper"
        >
          {portfolio.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="m-swiper__slide">
                  <div>
                    <img src={item.img} alt="" />
                  </div>
                  <div className="m-swiper__slide-desc">
                    <p className="m-swiper__slide-shortdesc a-paragraph">
                      {item.desc}
                    </p>
                    <a href={item.link}>Zobacz</a>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
}

export default Portfolio;
