import React, { useEffect, useContext, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { ApiContext } from "../App.js";
function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [text, setText] = useState("");
  const baseUrl = useContext(ApiContext);
  function handleSubmit(e) {
    e.preventDefault();
    function sendEmail() {
      const emailBody = {
        yourEmail: email,
        yourName: name,
        yourText: text,
      };
      const jsonToFormData = (json) => {
        try {
          const data = new FormData();

          for (let k in json) {
            data.append(k, json[k]);
          }

          return data;
        } catch (error) {
          console.error(error);
          return null;
        }
      };
      fetch(baseUrl + "/wp-json/contact-form-7/v1/contact-forms/10/feedback/", {
        method: "POST",
        body: jsonToFormData(emailBody),
      })
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.status !== "mail_sent") throw resp.message;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          document.getElementById("form").innerHTML =
            "<p class='o-contact__form-thankyou green'>Dziekuję, Twoja wiadomość została wysłana.</p>";
        });
    }
    sendEmail();
  }
  function handleNameInputChange(e) {
    setName(e.target.value);
  }
  function handleEmailInputChange(e) {
    setEmail(e.target.value);
  }
  function handleTextInputChange(e) {
    setText(e.target.value);
  }
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div data-aos="fade-up" className="a-container">
      <div className="a-wrapper">
        <h1 className="a-subtitle orange">Kontakt</h1>
        <div className="m-columns-50">
          <div className="m-columns-50__item m-columns-50__item--contact">
            <p className="contact-item">
              <i className="fa fa-envelope" aria-hidden="true"></i>
              <a href="mailto:a.cygnar@web4ads.pl">a.cygnar@web4ads.pl</a>{" "}
            </p>
            <p className="contact-item">
              <i className="fa fa-mobile" aria-hidden="true"></i>
              <a href="tel:+48792333814">792-333-814</a>
            </p>
            <p className="contact-item">
              <i className="fa fa-id-card" aria-hidden="true"></i>
              NIP: 6322028504
            </p>
          </div>
          <div id="form" className="m-columns-50__item">
            <form className="o-contact__form" onSubmit={handleSubmit}>
              <input
                onChange={handleNameInputChange}
                value={name || ""}
                type="text"
                placeholder="Imię i nazwisko"
              />
              <input
                onChange={handleEmailInputChange}
                value={email || ""}
                type="email"
                placeholder="E-mail"
              />
              <textarea
                onChange={handleTextInputChange}
                value={text || ""}
                placeholder="Treść wiadomości"
              />
              {
                <button
                  disabled={!email && !text}
                  className="o-contact__form-button a-btn "
                >
                  Wyślij
                </button>
              }
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
