export const displayLoading = () => {
  const loader = document.querySelector(".a-loading");
  loader.classList.add("display");
  setTimeout(() => {
    loader.classList.remove("display");
  }, 5000);
};

export const hideLoading = () => {
  const loader = document.querySelector(".a-loading");
  loader.classList.remove("display");
};

function Loader() {
  return <div className="a-loading"></div>;
}

export default Loader;
