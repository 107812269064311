import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import create from "../img/create.jpg";
import { useContext, useState } from "react";
import { ApiContext } from "../App.js";
import { useLocation } from "react-router-dom";

import Loader from "../components/Loader";
import { displayLoading } from "../components/Loader";
import { hideLoading } from "../components/Loader";
function Services() {
  const [services, setServices] = useState([]);
  const baseUrl = useContext(ApiContext);
  const location = useLocation();
  const slug = location.pathname.replace(/\//g, "");
  useEffect(() => {
    AOS.init();
    displayLoading();
    fetch(baseUrl + "/wp-json/wp/v2/pages?slug=" + slug)
      .then((response) => response.json())
      .then((data) => {
        setServices(data[0].acf.services);
        hideLoading();
      });
    // eslint-disable-next-line
  }, []);
  return (
    <div data-aos="fade-up" className="a-container">
      <div className="a-wrapper">
        <h1 className="a-subtitle orange">Usługi</h1>
        <div className="m-columns-50">
          <ul className="m-columns-50__item m-list">
            <Loader />
            {services.map((service, index) => {
              return (
                <li key={index} className="m-list__item green">
                  <i className="fa fa-terminal orange" aria-hidden="true"></i>
                  {service.services}
                </li>
              );
            })}
          </ul>
          <div className="m-columns-50__item">
            <img src={create} alt="Usługi" data-aos="fade-left" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Services;
