import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Homepage from "./pages/Homepage";
import Services from "./pages/Services";
import Portfolio from "./pages/Portfolio";
import About from "./pages/About";
import Contact from "./pages/Contact";
import ErrorPage from "./pages/ErrorPage";

import ParticleBackground from "react-particle-backgrounds";

import "./scss/App.scss";

import { createContext } from "react";
export const ApiContext = createContext(null);

function App() {
  // const baseUrl = "http://localhost/web4ads/wp-api";
  const baseUrl = "https://web4ads.pl/wp-api";
  const settings = {
    canvas: {
      canvasFillSpace: true,
      useBouncyWalls: false,
    },
    particle: {
      particleCount: 30,
      color: "#94ecbe",
      minSize: 2,
      maxSize: 5,
    },
    velocity: {
      directionAngle: 0,
      directionAngleVariance: 360,
      minSpeed: 1,
      maxSpeed: 3,
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.5,
      opacityTransitionTime: 3000,
    },
  };
  return (
    <Router>
      <div className="a-font-face main">
        <ParticleBackground settings={settings} />
        <ApiContext.Provider value={baseUrl}>
          <Routes>
            <Route
              element={
                <div className="main__wrapper">
                  <Header />
                  <Outlet />
                  <Footer />
                </div>
              }
            >
              <Route exact path="/" element={<Homepage />}></Route>
              <Route exact path="/uslugi" element={<Services />}></Route>
              <Route exact path="/portfolio" element={<Portfolio />}></Route>
              <Route exact path="/o-mnie" element={<About />}></Route>
              <Route exact path="/kontakt" element={<Contact />}></Route>
              <Route path="*" element={<ErrorPage />} />
            </Route>
          </Routes>
        </ApiContext.Provider>
      </div>
    </Router>
  );
}

export default App;
