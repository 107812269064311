import { NavLink } from "react-router-dom";
function Nav() {
  let activeClassName = "a-link--active";
  let notactiveClassName = "a-link";
  return (
    <nav id="desktopNav">
      <ul className="m-nav">
        <li>
          <NavLink
            to={`/`}
            className={({ isActive }) =>
              isActive ? activeClassName : notactiveClassName
            }
          >
            Start
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/uslugi`}
            className={({ isActive }) =>
              isActive ? activeClassName : notactiveClassName
            }
          >
            Usługi
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/portfolio`}
            className={({ isActive }) =>
              isActive ? activeClassName : notactiveClassName
            }
          >
            Portfolio
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/o-mnie`}
            className={({ isActive }) =>
              isActive ? activeClassName : notactiveClassName
            }
          >
            O mnie
          </NavLink>
        </li>
        <li>
          <NavLink
            to={`/kontakt`}
            className={({ isActive }) =>
              isActive ? activeClassName : notactiveClassName
            }
          >
            Kontakt
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Nav;
