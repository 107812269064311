import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
function Homepage() {
  useEffect(() => {
    AOS.init();
    const interval = setInterval(() => {
      document
        .querySelector(".a-change-text--company")
        .classList.toggle("is-active");
      document
        .querySelector(".a-change-text--you")
        .classList.toggle("is-active");
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div data-aos="fade-up" className="a-container p-homepage">
      <div className="p-homepage__wrapper">
        <h1 className="a-title">
          <span className="white">Adrian</span>{" "}
          <span className="orange">Cygnar</span>
        </h1>
        <h2 className="a-subtitle a-change-subtitle">
          <span className="white">Web4Ads - strona www dla</span>{" "}
          <span className="orange a-change-text a-change-text--company is-active">
            Firmy
          </span>
          <span className="orange a-change-text a-change-text--you">
            Ciebie
          </span>
        </h2>
        <Link className="a-btn" to="/portfolio">
          Sprawdź realizację
        </Link>
      </div>
    </div>
  );
}

export default Homepage;
