import { NavLink } from "react-router-dom";
function MobileNav({ isOpen, setOpen }) {
  let activeClassName = "a-link--active";
  let notactiveClassName = "a-link";
  const toggle = () => setOpen(!isOpen);
  return (
    <nav id="mobileNav">
      <ul className={isOpen ? "m-nav--mobile active" : "m-nav--mobile"}>
        <li>
          <NavLink
            onClick={toggle}
            to={`/`}
            className={({ isActive }) =>
              isActive ? activeClassName : notactiveClassName
            }
          >
            Start
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={toggle}
            to={`/uslugi`}
            className={({ isActive }) =>
              isActive ? activeClassName : notactiveClassName
            }
          >
            Usługi
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={toggle}
            to={`/portfolio`}
            className={({ isActive }) =>
              isActive ? activeClassName : notactiveClassName
            }
          >
            Portfolio
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={toggle}
            to={`/o-mnie`}
            className={({ isActive }) =>
              isActive ? activeClassName : notactiveClassName
            }
          >
            O mnie
          </NavLink>
        </li>
        <li>
          <NavLink
            onClick={toggle}
            to={`/kontakt`}
            className={({ isActive }) =>
              isActive ? activeClassName : notactiveClassName
            }
          >
            Kontakt
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default MobileNav;
