function Footer() {
  return (
    <footer className="o-footer a-container">
      <p className="a-paragraph--extrasmall">
        Image by{" "}
        <a href="https://pixabay.com/users/www_slon_pics-5203613/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3026190">
          www_slon_pics
        </a>{" "}
        and{" "}
        <a href="https://pixabay.com/users/xresch-7410129/?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3682509">
          Reto Scheiwiller
        </a>{" "}
        from{" "}
        <a href="https://pixabay.com//?utm_source=link-attribution&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=3026190">
          Pixabay
        </a>
      </p>
      <div className="o-footer__author">
        <p className="a-paragraph--small">
          Copyright © 2023 <a href="https://web4ads.pl">Web4ads</a>
        </p>
      </div>
    </footer>
  );
}

export default Footer;
