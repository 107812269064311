import Nav from "./Nav.js";
import MobileNav from "./MobileNav.js";
import logo from "../img/logo.png";
import { Turn as Hamburger } from "hamburger-react";
import { useState } from "react";
function Header() {
  const [isOpen, setOpen] = useState(false);
  return (
    <header className="a-container o-header">
      <img src={logo} className="a-logo" alt="Logo" />
      <Nav />
      <MobileNav isOpen={isOpen} setOpen={setOpen} />
      <Hamburger size={26} color="#fff" toggled={isOpen} toggle={setOpen} />
    </header>
  );
}

export default Header;
